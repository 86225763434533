import * as React from "react"
import * as Logo from "../images/polish-logo.svg" 
import "../styles/main.sass"

// markup
const IndexPage = () => {
  return (
    <main className="main-container">
      <img id="brand" src={Logo} alt="POLISH" />
      <p>· CAR DETAILING MÉXICO 2021 ·</p>
      <p>Comming soon..</p>
      <nav>
        <a href="https://www.facebook.com/polishmx">Facebook</a>
        <a href="https://www.instagram.com/polish_mexico/">Instagram</a>
      </nav>
    </main>
  )
}

export default IndexPage
